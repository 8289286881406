import React from "react"
import { Layout } from "@/layout"
import {
  Box,
  SEO,
  Width,
  Link,
  Text,
  Instagram,
  Facebook,
  MentorFooter,
  MentorHeader,
} from "@/components/index"
import Img from "gatsby-image"

export default function Mentorship({ data }) {
  return (
    <Layout headerType="checkout" headerTheme="light" footerTheme="light">
      <SEO
        title="Meet Sonora’s Mentors: Arianna Powell"
        description="Sonora pairs world-class mentors with intermediate guitar students in an intensive 3-month program to help them find their breakthrough on guitar."
      />
      <MentorHeader
        name="Arianna Powell"
        videoUrl="https://vimeo.com/493921594/71d0b8cb99"
      />
      <Width pt="3.6rem" pb="7.2rem">
        <Box
          width={["100%", null, 10 / 12, null]}
          mx="auto"
          display="flex"
          flexDirection={["column", null, "row", null]}
        >
          <Box width={["100%", null, 4 / 12, null]} pr="2em">
            <Box borderRadius=".6rem" overflow="hidden">
              <Img
                alt={"nicholas"}
                fluid={data.ariannaPowellImage.childImageSharp.fluid}
              />
            </Box>
          </Box>

          <Box width={["100%", null, 8 / 12, null]}>
            <Text as="h1" fontWeight="bold" mb="1.2rem" mt={["2rem", null, "0rem", null]}>
              Arianna Powell
            </Text>
            <Text level="1" mb="1.2rem">
              Touring and session guitarist
            </Text>
            <Box>
              <Link to="https://www.instagram.com/guitarianna" as="a">
                <Instagram mr="1.2rem" />
              </Link>
              <Link
                to="https://www.facebook.com/Arianna-Powell-123430184938213"
                as="a"
              >
                <Facebook />
              </Link>
            </Box>
            <Text level="2" mt="3.6rem">
              Arianna Powell is a touring and session guitarist based in Los
              Angeles, California. She has played several major national and
              international tours behind a few of the world’s biggest pop stars
              including Halsey, Nick Jonas, Black Eyed Peas, Chris Brown and one
              of Asia’s biggest stars, JJ Lin, under the direction of David
              Foster. She has played in house bands of American Idol, Taraji’s
              White Hot Holiday Special, and with Dua Lipa, Lauren Jauregui,
              Will.I.AM and more on late night TV shows including SNL, Ellen,
              Seth Meyers, James Cordon and Graham Norton. While touring,
              Arianna has performed many famous venues including Madison Square
              Garden, Wembley Stadium, O2, The Forum, Hollywood Bowl and more.
              In May 2017, she was featured in Guitar Player Magazine’s cover
              story, “50 Sensational Female Guitarists.” Known for her chord
              melodies and solo guitar playing, she has been hired to arrange
              and record stripped versions of pop songs with Halsey, Daya and
              more. Halsey’s Manic Deluxe album features several stripped tracks
              with Arianna’s playing and background vocals.
            </Text>
            <Text level="2">
              Arianna is also a composer and educator. She holds a Bachelors of
              Science degree in Music Education with a concentration in Jazz
              Guitar from Duquesne University. She has taught hundreds of
              private students over the years in a variety of genres such as
              blues, rock, Americana, jazz, metal and has a masterclass with
              PickUp Music on chord melodies. Arianna has endorsed several
              companies over the years including Gibson, Fender, Suhr, PRS,
              EarthQuaker Devices, Strymon Engineering, Keeley Electronics,
              Moody Leather and Kemper Amps. Arianna has been hired to demo
              several products for Epiphone, Fender, Orangewood Guitars, EQD and
              more. Her Instagram following exceeds 50k followers, a platform
              she devotes primarily to posting chord melodies. She’s involved in
              several creative endeavors, including writing loops for hip hop,
              pop and electronic producers, a solo guitar project and
              collaborating with various artists.
            </Text>
          </Box>
        </Box>
      </Width>
      <MentorFooter data={data} name="Arianna Powell" />
    </Layout>
  )
}

/* Image and Other GQL Queries */
export const mentorshipQuery = graphql`
  query {
    ariannaPowellImage: file(relativePath: { eq: "ariannaPowell-L.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    levelupImage: file(relativePath: { eq: "levelup.png" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    curriculumImage: file(relativePath: { eq: "curriculum.png" }) {
      childImageSharp {
        fluid(maxWidth: 570, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    communityImage: file(relativePath: { eq: "community.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    locationImage: file(relativePath: { eq: "location.png" }) {
      childImageSharp {
        fixed(width: 14, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
